import React, { Fragment } from 'react';
import Divider from './Divider';
import classes from './SectionHeading.module.css';

const SectionHeading = (props) => {
  return (
    <div className={classes.section_heading}>
      <Divider />
      {props.children}
    </div>
  );
};

export default SectionHeading;
