import React, { useState, useRef } from "react";
import classes from "./Footer.module.css";
import Label from "../common/Label";

import contactImg from "../../assets/portfolio_contact.png";

const Footer = () => {
  const [status, setStatus] = useState(""); // '', 'loading', 'success', 'error'
  const formRef = useRef(null); // Reference for the form

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");

    const formData = new FormData(e.target);

    try {
      const response = await fetch("/", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setStatus("success");
        formRef.current.reset(); // Clear form inputs
        setTimeout(() => setStatus(""), 3000); // Reset after showing success
      } else {
        console.error("Form submission error:", response.statusText);
        setStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatus("error");
    }
  };

  return (
    <footer className={classes.footer} id="contact">
      <div className={classes.heading}>
        <div className={classes.top_divider} />
        <Label text="04" gridCol="1/2" gridRow="1/1" />
        <Label text="jndev.io ©" gridCol="6/6" gridRow="1/1" />
        <Label text="front end developer" gridCol="9/9" gridRow="1/1" />
      </div>

      <div className={classes.img_container}>
        <img
          src={contactImg}
          alt="contact.png"
          className={classes.contact_image}
        />
      </div>

      <div className={classes.form_container}>
        <div
          className={`${classes.form_wrapper} ${
            status === "success" ? classes.fadeOut : classes.fadeIn
          }`}
        >
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit} // Attach the updated handleSubmit function here
            className={classes.form}
            ref={formRef}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <input
              type="text"
              placeholder="Name"
              id="name"
              name="name"
              className={classes.contact_input}
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              className={classes.contact_input}
              required
              id="email"
            />
            <textarea
              type="text"
              placeholder="Your message..."
              name="message"
              className={classes.contact_textarea}
              required
              id="message"
            ></textarea>
            <button type="submit" className={classes.contact_btn}>
              {status === "loading" ? "Sending..." : "Submit"}
            </button>
          </form>
        </div>

        <div
          className={`${classes.success_wrapper} ${
            status === "success" ? classes.fadeIn : classes.fadeOut
          }`}
        >
          <div className={classes.success_message}>
            Got it. I’ll reach out if needed
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
