import React from "react";
import SectionHeading from "../common/SectionHeading";
import Label from "../common/Label";
import GreyText from "../common/GreyText";
import classes from "./Section1.module.css";
import Divider from "../common/Divider";
import nameImg from "../../assets/portfolio_name.png";
const Section1 = () => {
  return (
    <div className={classes.sec_contain}>
      <section className={classes.hero}>
        <Divider />
        <div className={classes.hero_content_container}>
          <div className={classes.hero_greeting}>
            <Label text="Hello" />
          </div>
          {/* <div className={classes.hero_works}>
            <Label text="Selected works" />
            <GreyText text="2024" />
          </div> */}
          <div className={classes.hero_availability}>
            <Label text="availability " />
            <GreyText text="open to employment" />
          </div>
          <div className={classes.hero_num_label}>
            <Label text="01" />
          </div>
          <h1 className={classes.hero_h1}>frontend developer</h1>
        </div>
        <div className={classes.name_container_desktop}>
          <Divider />
          <img src={nameImg} alt="name" className={classes.name} />
        </div>
      </section>
    </div>
  );
};

export default Section1;
