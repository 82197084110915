import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import classes from "./Section2.module.css";
import Label from "../common/Label";
import GreyText from "../common/GreyText";
import ProjectWrapper from "../common/ProjectWrapper";
import projectPhoto from "../../assets/card-4.png";

const Section2 = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = (window.innerWidth - e.pageX) / 20;
      const y = (window.innerHeight - e.pageY) / 20;

      document.querySelectorAll(".parallax_img_global").forEach((image) => {
        image.style.transform = `translateX(${x * -1}px) translateY(${
          y * -1
        }px)`;
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <section className={classes.projects} id="projects">
      <SectionHeading>
        <div className={classes.project_content_container}>
          <div className={classes.section_num}>
            <Label text="02" />
          </div>
          <div className={classes.section_name}>
            <Label text="projects" />
          </div>
          <div className={classes.section_date}>
            <GreyText text="2025" />
          </div>
        </div>
      </SectionHeading>
      <div className={classes.projects_subheading}>
        {/* <p className={classes.projects_note}>
          Handpicked projects demonstrating coding skill and problem-solving
        </p> */}
        <h2 className={classes.projects_h2_large}>current projects</h2>
      </div>

      <ul className={classes.projects_ul}>
        <li className={classes.proj_li_1}>
          <div className={classes.mobile_img_container}>
            <img
              src={projectPhoto}
              alt="AI COMPANY LOGO"
              className={classes.mobile_image}
            />
          </div>
          <ProjectWrapper
            name="AI English Academy"
            description="React, MongoDB"
            date="2024"
            to="/project1"
          />
        </li>
        <img
          src={projectPhoto}
          alt="current project"
          className={`${classes.project_image1}  parallax_img_global`}
        />
      </ul>
    </section>
  );
};

{
  /* rapid */
}
{
  /* <li className={classes.proj_li_1}>
          <div className={classes.mobile_img_container}>
            <img
              src={null}
              alt='image of project 1'
              className={classes.mobile_image}
            /> 
          </div>
          <ProjectWrapper
            name='Rapid web dev agency'
            description='Webflow'
            date='2024'
            to='/project5'
          />
        </li> */
}
{
  /* REALTY */
}
{
  /* <li className={classes.proj_li_2}>
          <div className={classes.mobile_img_container}>
            <img
              src={realty}
              alt='image of project 1'
              className={classes.mobile_image}
            />
          </div>
          <ProjectWrapper
            name='NexaHomes Realty'
            description='React, Node.js, MongoDB'
            date='2024'
            to='/project1'
          />
        </li> */
}

{
  /* remingtons gastropub */
}
{
  /* <li className={classes.proj_li_3}>
          <div className={classes.mobile_img_container}>
            <img
              src={rems}
              alt='image of project 1'
              className={classes.mobile_image}
            />
          </div>
          <ProjectWrapper
            name='Remingtons Gastropub'
            description='Webflow, Adobe Photoshop'
            date='2024'
            to='/project2'
          />
        </li> */
}
{
  /* BRAIN TUMOR CLASSIFIER
        <li className={classes.proj_li_3}>
          <div className={classes.mobile_img_container}>
            <img
              src={brain}
              alt='image of project 1'
              className={classes.mobile_image}
            />
          </div>
          <ProjectWrapper
            name='Brain Tumor Classifier'
            description='Python, TensorFlow'
            date='2023'
            to='/project2'
          />
        </li> */
  /* NFT PROJECT*/
  /* <li className={classes.proj_li_4}>
          <div className={classes.mobile_img_container}>
            <img
              src={nft_logo}
              alt='image of project 1'
              className={classes.mobile_image}
            />
          </div>
          <ProjectWrapper
            name='NFT Marketplace'
            description='React'
            date='2022'
            to='/project4'
          />
        </li> */
  /* <img
          src={realty}
          alt='current project'
          className={`${classes.project_image2}  parallax_img_global`}
        />
        <img
          src={rems}
          alt='current project'
          className={`${classes.project_image3}  parallax_img_global`}
        />
        <img
          src={nft_logo}
          alt='current project'
          className={`${classes.project_image4} parallax_img_global`}
        /> */
  /* <img
          src={tmap}
          alt='current project'
          className={`${classes.project_image5} parallax_img_global`}
        /> */
}

export default Section2;
