import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Section1 from '../components/Section1/Section1';
import Section2 from '../components/Section2/Section2';
import Section3 from '../components/Section3/Section3';
import Footer from '../components/Footer/Footer';
import classes from './Home.module.css';
const Home = () => {
  return (
    <div className='App'>
      <Navbar />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
    </div>
  );
};

export default Home;
