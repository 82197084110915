import React, { Fragment } from "react";
// import SectionHeading from '../common/SectionHeading';
import Label from "../common/Label";
import classes from "./Section3.module.css";
const Section3 = () => {
  return (
    <Fragment>
      <div className={classes.heading} id="about">
        <div className={classes.top_divider} />
        <Label text="get to know me" gridCol="1/6" />
        <Label text="about" gridCol="6/7" />
        <p className={classes.grey_label}>🇺🇸🇺🇸🇺🇸🇺🇸🇺🇸</p>
      </div>
      <section className={classes.about}>
        {/* wrapped this in a div to be able to change css on media queries */}
        <div className={classes.label_container}>
          <Label text="03" />
        </div>
        {/*/////////////////////////////////////////////////////////////// 
        
        FULL STACK
SOFTWARE DEV
WEBFLOW GURU*/}
        <div className={classes.about_avatar}></div>
        <div className={classes.heading_wrapper}>
          <h2 className={classes.about_h2}>DISCIPLINED DEVELOPER </h2>
          <h2 className={classes.about_h2}>BUILDING WITH PURPOSE</h2>
          <h2 className={classes.about_h2}> & STRATEGY</h2>
        </div>
        <p className={classes.about_subheading}>
          TURNING VISION INTO SCALABLE IMPACT
        </p>
        {/* text block part */}
        <div className={classes.about_text_block_container}>
          <div className={classes.about_text_block}>
            <Label text="01" />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Desire to Excel</h3>
              <p className={classes.about_text}>
                I’m driven by a deep aversion to mediocrity, always pushing to
                position myself among the best.
              </p>
            </div>
          </div>
          <div className={classes.about_text_block}>
            <Label text="02" />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Passion for Learning</h3>
              <p className={classes.about_text}>
                From coding to languages and books, I learn to challenge myself
                to keep growing
              </p>
            </div>
          </div>

          <div className={classes.about_text_block}>
            <Label text="03" />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Strategic Thinking</h3>
              <p className={classes.about_text}>
                I always envision the whole when making decisions, staying
                neutral and open to opposing views, and breaking challenges into
                actionable steps for efficient results.
              </p>
            </div>
          </div>
        </div>
        {/* services */}
        <h3 className={classes.about_h3__services}>about</h3>
        <div className={classes.services_container}>
          <div className={classes.services_content_container}>
            <Label text="01" />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                4 Core Programming Languages
              </strong>
              <p className={classes.services_description}>
                C++, JavaScript, Python, Swift
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          <div className={classes.services_content_container}>
            <Label text="02" />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                3 Foreign Languages
              </strong>
              <p className={classes.services_description}>
                Spanish, Portuguese, Italian
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          <div className={classes.services_content_container}>
            <Label text="03" />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                AI-Enhanced Applications
              </strong>
              <p className={classes.services_description}>
                Leveraging AI to build smarter, faster, and more interactive
                platforms.
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          {/* <div className={classes.services_content_container}>
            <Label text="04" />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                Security-Conscious Development
              </strong>
              <p className={classes.services_description}>
                Writing code with an eye toward best practices in cybersecurity
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div> */}
        </div>

        {/* numbers */}
        {/* <h3 className={classes.about_h3__numbers}>numbers</h3>
        <div className={classes.numbers_container}>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>4</div>
              <p className={classes.number_description}>
                core programming languages
              </p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>2</div>
              <p className={classes.number_description}>languages spoken</p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>3</div>
              <p className={classes.number_description}>
                operating systems proficient
              </p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>500+</div>
              <p className={classes.number_description}>Leetcodes solved</p>
            </div>
          </div>
        </div> */}
      </section>
    </Fragment>
  );
};

export default Section3;
